import React, { useState, useEffect, useContext } from 'react';
import Carousel from "nuka-carousel"
import { Link } from 'react-router-dom';
import styles from './HeaderSection.module.css'
import { CartContext } from '../CartContext';

function HeaderSection() {
    const { categories, sliders } = useContext(CartContext)
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleSlideAfterChange = (slideIndex) => {
        setCurrentSlideIndex(slideIndex);
    };
    return (

        <div>
            <div className={styles.mainHeader} style={{ width: '100%', background: sliders[currentSlideIndex]?.color }}>
                <div className={styles.sceondHeader}>
                    <div className={`${styles.categroySection} col-2`}>
                        <ul>
                            {
                                categories && categories.slice(0, 10).map(data =>
                                    <li key={data.id}><Link to={`/category/${data.slug}`}>{data.name}</Link> </li>
                                )
                            }
                        </ul>
                    </div>
                    <div className='col-lg-10'>
                        <Carousel autoplay withoutControls wrapAround afterSlide={handleSlideAfterChange} >
                            {
                                sliders && sliders.map(data =>
                                        <Link key={data.id} to={data.url}><img className={styles.CarouselImage} src={data.image} width={1000} height={1000} alt="" /></Link>
                                )
                            }
                            {/* <Image className={styles.CarouselImage} src={image2} alt="" /> */}
                        </Carousel>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default HeaderSection;