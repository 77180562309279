import { Helmet } from "react-helmet";
import RegistrationForm from "./Registration";

function RegistrationPage() {

    return (
        <>
            <Helmet>
                <title>Registrations !! Royel Shopping</title>
            </Helmet>
            <RegistrationForm />
        </>

    )
}

export default RegistrationPage;
