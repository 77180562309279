import React from 'react';
import './LoadingRoyel.css'

function LoadingRoyel() {
  return (
    <ul className="loading-ul">
      <li className="loading-letter loading-letter-r">S</li>
      <li className="loading-letter loading-letter-o">A</li>
      <li className="loading-letter loading-letter-y">F</li>
      <li className="loading-letter loading-letter-e">A</li>
      <li className="loading-letter loading-letter-l">-</li>
      <li className="loading-letter loading-letter-s">B</li>
      <li className="loading-letter loading-letter-h">U</li>
      <li className="loading-letter loading-letter-o2">Y</li>
      {/* <li className="loading-letter loading-letter-p">p</li>
      <li className="loading-letter loading-letter-p2">p</li>
      <li className="loading-letter loading-letter-i">i</li>
      <li className="loading-letter loading-letter-n">n</li>
      <li className="loading-letter loading-letter-g">g</li> */}
    </ul>
  );
}

export default LoadingRoyel;