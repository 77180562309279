import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styles from './HeaderCategory.module.css'
import { CartContext } from '../CartContext';



function HeaderCategory() {
    const { categories } = useContext(CartContext)



    const sortCategory = categories.sort((a, b) => parseFloat(b.products.length) - parseFloat(a.products.length)).slice(0, 6)
    return (
        <div>
            <div className={`${styles.cateMain}`}>
                <div className={styles.cate2nd}>
                    <h4 className='text-center mb-5'>Popular Categories</h4>
                    <ul>

                        {
                            sortCategory.map(data =>
                                <li key={data.id}>
                                    <Link className='text-dark text-decoration-none' to={`/category/${data.slug}`} >
                                        <div className={styles.image}>
                                            <img src={data.products[0] && data.products[0].images && data.products[0].images[0].image} alt={data.products[0] && data.products[0].name} width={200} height={200} />
                                            <meta property="og:image" content={data.products[0] && data.products[0].images && data.products[0].images[0].image} />
                                        </div>
                                        <p >{data.name}</p>
                                    </Link>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        </div >
    );
}

export default HeaderCategory;