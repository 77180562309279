
import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { fetchUrl } from '../config';
import LoadingDark from '../LodingDark/LoadingDark';
import { CartContext } from '../CartContext';




function ProfilePage() {
    const { setShowAlert, setAlertText, setAlertVariant, setIsLogin } = useContext(CartContext)
    const [myOrder, setMyorder] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    const route = useNavigate()
    const token = Cookies.get('login_token');




    useEffect(() => {

        if (token) {
            fetch(`${fetchUrl}/api/user/order/?ordering=-id`, { headers: token ? { "Authorization": `Bearer ${token}` } : {} })
                .then(res => res.json())
                .then(data => setMyorder(data))
                .then(setIsLoading(false))
        }
        else {
            route('/login')
            setIsLoading(true)
        }

    }, [route, token])

    const handleLogout = () => {
        route('/')
        Cookies.remove('login_token');
        setIsLogin(false)
        setShowAlert(true)
        setAlertText('Logout success')
        setAlertVariant('warning')
            ;


    }
    // console.log(myPoint[0].points)
    return (
        <div>
              <Helmet>
                <title> Profile !!  Royel Shopping !!</title>
            </Helmet>
            <div className='text-center my-5 pt-5'>
                <div className='mt-5'>
                    <div>
                        <Link to='/profile/account'><button type='button' className=' btn btn-dark'>My Acount</button></Link>
                        <Link to='/profile/reviews'><button type='button' className='ms-2 btn btn-dark'>My Reviews</button></Link>
                        <button type='button' onClick={handleLogout} className='btn btn-dark ms-2'>Logout</button>
                    </div>

                </div>

            </div>
            {isLoading ? <LoadingDark /> : null}
            <h5 className='text-center mt-5'>My Orders</h5>
            {
                myOrder && myOrder.length > 0 ?

                    myOrder && myOrder.map(data =>
                        <div className='container my-5' key={data.id}>

                            <div className='row border p-3'>

                                <div className='col-md-6 mb-2 row '>
                                    <p className='mt-2 ms-1 text-primary col-12'>Order ID - {data.order_id}</p>
                                    <div className='col-12 order-md-first'>  {data.order_image && <img src={data.order_image} alt="pdimage" width={150} height={150} />}</div>

                                </div>
                                <div className='col-md-6'>
                                    <h5><Link className=' text-decoration-none' to={`/product/${data.product_slug}`}>{data.products}</Link> </h5>
                                    <p>Price  <b>{data.price} tk.</b></p>
                                    <p>Item  <b>{data.quantity}</b></p>
                                    <p>Total Ammount <b>{data.total_amount} tk.</b></p>
                                    <p>Order Status  <span className='text-success'>{data.order_status}</span></p>
                                </div>
                            </div>
                        </div>

                    ) : <p className='text-center'>No Order Yet</p>
            }
        </div>
    );
}

export default ProfilePage;