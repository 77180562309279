/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import ProductView from '../ProductView/ProductView';
import { CartContext } from '../CartContext';

function HomeCategoryProducts() {
 const {categories}= useContext(CartContext)
    const notNullCate = categories.filter(data => data.products && data.products.length > 0)
    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }
    const randomCategory = shuffleArray(notNullCate)
    return (
        <div>
            {
                randomCategory.slice(0, 3).map(data =>
                    <div className='mt-5' key={data.id}>
                        <div className='newArrival'>
                            <h4 className=''>{data.name}</h4>
                            <div><Link to={`category/${data.slug}`}> <button type='button' className='btn btn-dark'>See all</button></Link> </div>
                        </div>
                        <ProductView products={data.products && shuffleArray(data.products)} />
                    </div>
                )
            }
        </div>
    );
}

export default HomeCategoryProducts;