import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Loading from '../Loading/Loading';
import './Error.css'

function Error() {
   const [loading , setLoding] = useState(true)
    useEffect(()=> {
        setTimeout(() => {
            setLoding(false)
        }, 3000);
    })
    return (
        <div className="xxError container d-flex align-items-center">
            {loading ? <Loading /> : null}
              <Helmet>
                <title>404 || Page is not found</title>
            </Helmet>
            <div className="login-form">
                <h2>404 Pags is not found</h2>
                <p>Back to the <Link to = '/' >home</Link> page</p>
            </div>
        </div>
    );
}

export default Error;