/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faAngleDown, faBars, faBagShopping, faUser } from '@fortawesome/free-solid-svg-icons'
import { Slide } from "react-awesome-reveal";
import Cart from '../Carts/Cart';
import LoadingDark from '../LodingDark/LoadingDark';
import styles from './Nav.module.css'
import { CartContext } from '../CartContext';
import logo from '../Logo/Safa Logo.png'

function Nav() {
    const { isLogin, categories } = useContext(CartContext)
    const [showCate, setShowCate] = useState(false)
    const handleShowCate = () => {
        setShowCate(!showCate)
    }

    const pathnameX = window.location.pathname;
    const isHomePage = pathnameX === '/'
    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setIsScrolled(scrollTop > 450);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    const categroy = categories


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [serachInput, setSearchInput] = useState('')
    const handelSearch = (e) => {
        setSearchInput(e.target.value)
    }
    const router = useNavigate()
    const search = () => {
        if (serachInput.length > 0) {
            router(`search/${serachInput}`)
        }
    }

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (isLogin) {
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }

    }, [isLogin]);

    return (
        <div>
            <meta property="og:image" content={logo} />

            <div className={styles.main}>
                {isLoading ? <LoadingDark /> : null}
                <div>
                    <div style={!isHomePage ? { display: "none" } : null} className={styles.navx}>
                        <div>
                            <Link to='/'>
                                <img src={logo} width={200} alt='logo' />

                            </Link>
                        </div>
                        <div>
                            <ul>
                                <li><Link to="/">HOME</Link></li>
                                <li><Link to="/shop">SHOP</Link></li>
                                <li><Link to="/">BLOG</Link></li>
                                <li><Link to="/pages/contact">OUR STORE</Link></li>


                            </ul>
                        </div>
                        <div>
                            <div className='d-flex'>
                                <div>
                                    <FontAwesomeIcon icon={faUser} />  {isLogin ? <Link to="/profile">Profile</Link> : <span > <Link to='/login'>Log in </Link> / <Link to='/registration'>Registration</Link></span>}
                                </div>
                                <div className='ms-3'>
                                    <Cart cartIcon={<FontAwesomeIcon icon={faBagShopping} />} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.serachCateSection}>
                        <div className={styles.serachCate2nd}>
                            <div>
                                <div className={styles.categoryBtn}><FontAwesomeIcon icon={faBars} /> Categories </div>
                            </div>
                            <div>
                                <div className={styles.search}>
                                    <input onChange={handelSearch} value={serachInput} className={styles.searchBox} type="text" placeholder='Search here..' />
                                    <FontAwesomeIcon onClick={search} className={styles.searchIcon} icon={faSearch} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <nav className={`${styles.navbarx} ${isScrolled || !isHomePage ? styles.scrolled : ''}`}>
                    <div className={styles.nav2nd}>
                        <div className={styles.navx}>
                            <div>
                                <Link to='/'>
                                    <img src={logo} width={200} alt='logo' />

                                </Link>
                            </div>
                            {/* <div className={styles.search}>
                            <input onChange={handelSearch} value={serachInput} className={styles.searchBox} type="text" placeholder='Search here..' />
                            <FontAwesomeIcon onClick={search} className={styles.searchIcon} icon={faSearch} />

                        </div> */}
                            <div>
                                <ul>
                                    <li><Link to="/">HOME</Link></li>
                                    <li><Link to="/shop">SHOP</Link></li>
                                    <li><Link to="/">BLOG</Link></li>
                                    <li><Link to="/pages/contact">OUR STORE</Link></li>
                                </ul>
                            </div>
                            <div>
                                <div className='d-flex'>
                                    <div>
                                        <FontAwesomeIcon icon={faUser} />   {isLogin ? <Link to="/profile">Profile</Link> : <span > <Link to='/login'>Log in </Link> / <Link to='/registration'>Registration</Link></span>}
                                    </div>
                                    <div className='ms-3'>
                                        <Cart cartIcon={<FontAwesomeIcon icon={faBagShopping} />} />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={styles.serachCateSection}>
                            <div className={styles.serachCate2nd}>
                                <div>
                                    <div onClick={handleShowCate} className={styles.categoryBtn}><FontAwesomeIcon icon={faBars} /> Categories <FontAwesomeIcon className={`${styles.arrowIcon} ${showCate ? styles.showCates : styles.hideCates}`} icon={faAngleDown} /> </div>
                                </div>
                                <div>
                                    <div className={styles.search}>
                                        <input onChange={handelSearch} value={serachInput} className={styles.searchBox} type="text" placeholder='Search here..' />
                                        <FontAwesomeIcon onClick={search} className={styles.searchIcon} icon={faSearch} />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.headerCate}>
                            {
                                showCate ?

                                    <Slide direction='down'>
                                        <ul>
                                            {
                                                categroy && categroy.map(data =>
                                                    <Link key={data.id} to={`/category/${data.slug}`}>  <li onClick={handleShowCate}>{data.name} </li></Link>
                                                )
                                            }
                                        </ul>
                                    </Slide>
                                    : null}
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default Nav;