/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import styles from './Order.module.css'
import ColorSize from '../ColorSize/ColorSize';
import { fetchUrl } from '../config';
import LoadingDark from '../LodingDark/LoadingDark';
import { CartContext } from '../CartContext';

function OrderModal() {
    const [profile, setProfile] = useState({})
    const [deliveryCharge, setDeliveryCharge] = useState([])
    const route = useNavigate()
    const { setOrderData, orderProduct, isHomePage, quantity, inputValue, setInputValue, setQuantity } = useContext(CartContext)
    const [token, setToken] = useState('')
    // const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit, watch, setValue, reset, formState: { errors } } = useForm();
    const product = orderProduct;

    const [responseData, setResponseData] = useState({})

    useEffect(() => {
        window.scrollTo(0, 0)
        if (product === null) {
            route('/')
        }
    }, [product, route])


    useEffect(() => {

        fetch(`${fetchUrl}/api/delivery-charge/1/`)
            .then(res => res.json())
            .then(data => setDeliveryCharge(data))

    }, [])

    let deliveryFee = deliveryCharge.out_dhaka ? deliveryCharge.out_dhaka : 120;
    if (watch("delivery-area") === 'inside-dhaka') {
        deliveryFee = deliveryCharge && deliveryCharge.in_dhaka
    }
    else if (watch("delivery-area") === 'out-dhaka') {
        deliveryCharge && deliveryCharge.out_dhaka
    }
    else if (watch("delivery-area") === 'near-dhaka') {
        deliveryFee = 100
    }




    useEffect(() => {
        const login = Cookies.get('login_token')
        if (login) {
            setToken(login)
        }
    }, []);


    useEffect(() => {
        if (token) {
            fetch(`${fetchUrl}/api/profile/`, {
                headers: { "Authorization": `Bearer ${token}` }
            })
                .then(res => res.json())
                .then(data => setProfile(data[0]))
        }
    }, [token])


    useEffect(() => {
        if (token) {
            setValue('name', profile.fname ? `${profile.fname[0]} ${profile.lname}` : '');
            setValue('phone', profile.phone || '');
            setValue('address', `${profile.address}, ${profile.upzila}, ${profile.district}` || '');
        }
    }, [profile, setValue, token])

    useEffect(() => {
        if (product && window.dataLayer) {
            window.dataLayer.push({
                event: "begin_checkout",
                ecommerce: {
                    currency: "BDT",
                    value: product.price,
                   
                    items: [
                        {
                            item_id: product.slug,
                            item_name: product.name,
                            quantity,
                            price: product.price,
                        }
                    ]
                }
            });
        }
    }, [product])

    const handleFormSubmit = async () => {
        setIsLoading(true)
        const orderData = new FormData()
        orderData.append('name', watch('name'))
        orderData.append('phone', watch('phone'))
        orderData.append('address', watch('address'))
        orderData.append('note', watch('note'))
        orderData.append('products', product && product.name)
        orderData.append('product_slug', product && product.slug)
        orderData.append('order_image', product.images && product.images[0].image)
        orderData.append('price', product && product.price)
        orderData.append('point', token && product ? (product.price * quantity * 0.01).toFixed(0) : 0)
        orderData.append('size', inputValue.size)
        orderData.append('color', inputValue.color)
        orderData.append('quantity', quantity)
        orderData.append('delivery_fee', deliveryFee)
        orderData.append('total_amount', (product && product.price * quantity + deliveryFee))
        try {
            const res = await fetch(`${fetchUrl}/api/order/create/`, {
                headers: token ? { "Authorization": `Bearer ${token}` } : {},
                method: 'POST',
                body: orderData

            })
            if (res.ok) {
                const resx = await res.json()
                setOrderData(resx)
                route('/order-confirm')
                setTimeout(() => {
                    setIsLoading(false)
                }, 1000);
            }
            else {
                setIsLoading(false)
            }

            reset(res)
        }

        catch (err) {
            setIsLoading(false)
            setResponseData({ fail: 'Fail To Order' })
        }
    }
    // console.log(watch('name'), watch('address'), watch('phone'), quantity, inputValue, )

    return (
        <>
            <div className={styles.mtop} />
            <Helmet>
                <title>Checkout !! Safa Buy</title>
            </Helmet>
            <div>
                {
                    responseData.faol ?
                        <div>
                            <div className='col-md-4 mx-auto'>
                                <h1 >!SORRY</h1>
                                <p>{responseData.fail}</p>
                                <p>please try again</p>
                            </div>

                        </div>
                        :
                        <div>
                            <div className={styles.formModalMain}>
                                {isLoading ? <LoadingDark /> : null}
                                <div className={`${styles.orderImage}`}>
                                    {product ? <img src={product.images && product.images[0].image} alt={product.name} width={100} height={100} /> : null}
                                </div>
                                <div className='mt-3'>
                                    <p>অর্ডার করতে <span className='text-danger'>আপনার  নাম, নাম্বার, ঠিকানা </span>দিয়ে <span className='text-success'>অর্ডার কনফার্ম করুন</span> বাটনে ক্লিক করুন।</p>
                                </div>
                                <form onSubmit={handleSubmit(handleFormSubmit)}>
                                    {/* register your input into the hook by invoking the "register" function */}
                                    <label className='mb-1 ps-1' >Name</label>
                                    <input className='form-control mb-3' placeholder='নাম' {...register("name", { required: true, maxLength: 100 })} />
                                    {errors.name && <Alert variant='danger' >Please put a valid Name</Alert >}
                                    <label className='mb-1 ps-1' >Phone Number</label>
                                    <input className='form-control mb-3' placeholder='১১ ডিজিটের ফোন নাম্বার' type="text" {...register("phone", { pattern: /^[0-9]+$/, minLength: 11, maxLength: 11, required: true })} />
                                    {errors.phone && <Alert variant='danger' >Please put a valid Phone Number</Alert >}
                                    {/* include validation with required or other standard HTML validation rules */}
                                    <label className='mb-1 ps-1' >Address</label>
                                    <input className='form-control mb-3' placeholder='ঠিকানা' {...register("address", { required: true, maxLength: 450 })} />
                                    {/* errors will return when field validation fails  */}
                                    {errors.address && <Alert variant='danger'>Please put a valid Address</Alert >}
                                    <label className='mb-1 ps-1' >Special Note (optional)</label>
                                    <input className='form-control mb-3' placeholder='কিছু বলার থাকলে লিখুন' {...register("note", { maxLength: 450 })} />
                                    <div>
                                        {
                                            isHomePage ?
                                                <div className='form-control mb-3'>
                                                    <ColorSize product={product} inputValue={inputValue} setInputValue={setInputValue} quantity={quantity} setQuantity={setQuantity} />
                                                </div> : null
                                        }
                                    </div>
                                    <div className='form-control mb-3 p-3'>
                                        <h6>ডেলিভারি এরিয়া</h6>
                                        <div className='form-check'>
                                            <input className='form-check-input' {...register("delivery-area", { required: true })} type="radio" value="inside-dhaka" />
                                            <label className='form-check-label'>ঢাকার মধ্যে - {deliveryCharge && deliveryCharge.in_dhaka_bangla} tk</label>
                                        </div>
                                        <div className='form-check'>
                                            <input className='form-check-input' {...register("delivery-area", { required: true })} type="radio" value="out-dhaka" defaultChecked />
                                            <label className='form-check-label'>ঢাকার বাইরে - {deliveryCharge && deliveryCharge.out_dhaka_bangla} tk</label>
                                        </div>
                                    </div>

                                    <div className='form-control mb-3 p-3'>
                                        <div className='d-flex'>
                                            <div className='col-md-3 col-5'>Items</div>
                                            <div className='ms-5' ><b>{quantity} Peice</b></div>
                                        </div>
                                        <div className='d-flex'>
                                            <div id='totlaPrice' className='col-md-3 col-5'>Total  Price</div>
                                            <div className='ms-5' > <b>{product && product.price * quantity + deliveryFee} tk</b></div>
                                        </div>

                                    </div>
                                    {/* <div className='form-control mb-5'>
                                    <h6 className='my-3'>Select Payment Method </h6>
                                    <div className='row'>
                                        <div className={`${styles.radioOptionPayment} col-lg-6`}>
                                            <input type="radio" name="payment" id="card" disabled />
                                            <label htmlFor="card"><FontAwesomeIcon icon={faCreditCard} /> Credit/ Debit Card</label>
                                        </div>
                                        <div className={`${styles.radioOptionPayment} col-lg-6`}>
                                            <input type="radio" name="payment" id='bkash' disabled />
                                            <label htmlFor="bkash">
                                                <Image className='ms-1' src={bkash} alt='bks' height={20} width={20} /> Bkash
                                            </label>
                                        </div>
                                    </div>
                                    <div className='row'>  <div className={`${styles.radioOptionPayment} col-lg-6  `}>
                                        <input type="radio" name="payment" id='nagad' disabled />
                                        <label htmlFor="nagad"><Image className='text' src={nagad} alt='bks' height={20} width={25} />Nagad</label>
                                    </div>
                                        <div className={`${styles.radioOptionPayment} col-lg-6 order-lg-first`}>
                                            <input type="radio" name="payment" id='cash' defaultChecked />
                                            <label htmlFor="cash"><FontAwesomeIcon icon={faMoneyBillTransfer} fade style={{ color: "black", }} /> Cash on Delivery</label>
                                        </div>


                                    </div>

                                </div> */}

                                    <input className='btn btn-primary w-100' type="submit" value="অর্ডার কনফার্ম করুন" />
                                </form>
                            </div>
                        </div >
                }
            </div >
        </>
    );
}

export default OrderModal;