/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import ProductView from '../../ProductView/ProductView';
import '../../globals.css'


function Search(props) {
    const [filter, setFileter] = useState('default')
    const [seacrhProducts, setsearchProducts] = useState([])
    const { allProducts } = props
    const handleOnchange = (e) => {
        setFileter(e.target.value)

    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (filter === 'default') {
            const defalut = allProducts && allProducts.sort((a, b) => parseFloat(a.id) - parseFloat(b.id)).slice(0)
            setsearchProducts(defalut)
        }


        if (filter === 'h2l') {
            const h2l = allProducts.sort((a, b) => parseFloat(b.price) - parseFloat(a.price)).slice(0)
            setsearchProducts(h2l)
        }

        if (filter === 'l2h') {
            const l2h = allProducts.sort((a, b) => parseFloat(a.price) - parseFloat(b.price)).slice(0)
            setsearchProducts(l2h)

        }
    }, [allProducts, filter])
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(20000);
    const [minRange] = useState(0);
    const [maxRange] = useState(20000);

    const handleMinPriceChange = (value) => {
        setMinPrice(value);
    };

    const handleMaxPriceChange = (value) => {
        setMaxPrice(value);
    };

    
    const priceRangeProducts = seacrhProducts && seacrhProducts.filter(data => data.price >= minPrice && data.price <= maxPrice)

    return (
        <div>
            <div className='priceRange'>
                <div >
                    <div className='priceRange2'>
                        <div >
                            <label className='d-block'>Min Price:  ৳ {minPrice}</label>
                            <input
                                type="range"
                                min={minRange}
                                max={maxRange}
                                value={minPrice}
                                onChange={(e) => handleMinPriceChange(Number(e.target.value))}
                                id='myinput'
                                style={{
                                    background: `linear-gradient(to right, rgb(92 50 111) 0%, #327373 ${((minPrice - minRange) / (maxRange - minRange)) * 100}%, #DEE2E6 ${((minPrice - minRange) / (maxRange - minRange)) * 100}%, #DEE2E6 100%)`,
                                }}
                            />
                        </div>
                        <div className='ms-3'>
                            <label className='d-block'>Max Price: ৳ {maxPrice}</label>
                            <input
                                type="range"
                                min={minRange}
                                max={maxRange}
                                value={maxPrice}
                                onChange={(e) => handleMaxPriceChange(Number(e.target.value))}
                                id='myinput'
                                style={{
                                    background: `linear-gradient(to left, #DEE2E6  0%, #DEE2E6  ${((maxRange - maxPrice) / (maxRange - minRange)) * 100}%, #327373 ${((maxRange - maxPrice) / (maxRange - minRange)) * 100}%, rgb(92 50 111) 100%)`, 
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className='selectOption'>
                    <div>
                        <span className='me-3'> Sort By : </span>
                    </div>
                    <div>
                        <select onChange={handleOnchange} className=" form-select form-select-sm ">
                            <option value="default">Newest</option>
                            <option value="h2l">High to Low</option>
                            <option value="l2h">Low to High</option>
                        </select>
                    </div>
                </div>
            </div>
            <hr />
            <ProductView products={priceRangeProducts} />
        </div>
    );
}

export default Search;