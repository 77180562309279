import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Search from "./Search/Search";
import { CartContext } from '../CartContext';


function SearchPage() {
  const {products}= useContext(CartContext)
  const { searchParams } = useParams()
  const allProducts = products
 
  const filterProducts = allProducts.filter(data => data.name.toLowerCase().indexOf(searchParams.toLowerCase()) !== -1)
  return (
    <div className='mtop'>

      <Helmet>
        <title>{searchParams || 'Royel Shopping'}</title>
      </Helmet>
      {filterProducts.length > 0 ?
        <div>
          <h3 className=" text-center">Products showing of {searchParams}</h3>
          <Search allProducts={filterProducts} />
        </div>
        : <h4 className="text-center my-5">!!! No Products Available !!!</h4>
      }
    </div>
  );
};

export default SearchPage;