import React from 'react';
import './LoadingNew.css'

function LoadingNew() {
    return (
        <div>
            <div className="mainDiv1">
                <div className="mainDiv">
                    <div className="div2">
                        <div className="div3" />
                        <div className="div4" />
                        <div className="div5" />
                        <div className="div6" />

                    </div>
                </div>

            </div>
        </div>
    );
}

export default LoadingNew;