import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { fetchUrl } from '../../config';
import InfoEdit from './InfoEdit';
import LoadingDark from '../../LodingDark/LoadingDark';



function AccountPage() {
    const token = Cookies.get('login_token');
    const [profile, setProfile] = useState([])
    const [isLoading, setIsLoading] = useState([])

    useEffect(() => {

        if (token) {
            fetch(`${fetchUrl}/api/profile/`, { headers: token ? { "Authorization": `Bearer ${token}` } : {} })
                .then(res => res.json())
                .then(data => setProfile(data))
                .then(setIsLoading(false))
        }
        else {

            setIsLoading(true)
        }

    }, [token])

    return (
        <div>
            {isLoading ? <LoadingDark /> : null}
            {profile && profile[0] ?

                <div className='container mt-5 pt-5'>
                    <h5 className='mt-5'>User Information</h5>
                    <div className='row mt-3'>
                        <div className='col-md-2 col-4'><b>Name</b></div>
                        <div className='col-md-1 col-1'><b>:</b></div>
                        <div className='col-md-9 col-7'>
                            <b>{ profile[0].fname} { profile[0].lname}</b>
                        </div>
                    </div>

                    <div className='row mt-1'>
                        <div className='col-md-2 col-4'><b>Email / Username</b></div>
                        <div className='col-md-1 col-1'><b>:</b></div>
                        <div className='col-md-9 col-7'>
                            <b>{ [profile[0].email]}</b>
                        </div>
                    </div>
                    <div className='row mt-1'>
                        <div className='col-md-2 col-4'><b>Phone</b></div>
                        <div className='col-md-1 col-1'><b>:</b></div>
                        <div className='col-md-9 col-7'>
                            <b>{  [profile[0].phone]}</b>
                        </div>
                    </div>
                    <div className='row mt-1'>
                        <div className='col-md-2 col-4'><b>Address</b></div>
                        <div className='col-md-1 col-1'><b>:</b></div>
                        <div className='col-md-9 col-7'>
                            <b>{[profile[0].address]}</b>
                        </div>
                    </div>
                    <div className='row mt-1'>
                        <div className='col-md-2 col-4'><b>Upzila</b></div>
                        <div className='col-md-1 col-1'><b>:</b></div>
                        <div className='col-md-9 col-7'>
                            <b>{[profile[0].upzila]}</b>
                        </div>
                    </div>
                    <div className='row mt-1'>
                        <div className='col-md-2 col-4'><b>District</b></div>
                        <div className='col-md-1 col-1'><b>:</b></div>
                        <div className='col-md-9 col-7'>
                            <b>{[profile[0].district]}</b>
                        </div>
                    </div>
                    <InfoEdit profile={profile[0]} />
                </div>









                :
                <h1>Please <a href="/profile">Log in</a> first</h1>
            }
        </div>
    );
}

export default AccountPage;