import React, {useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ProductView.module.css'
import { CartContext } from '../CartContext';

function OrderModalView(props) {
    const { product } = props
    const { setOrderProduct, setIsHomPage } = useContext(CartContext)
    const route = useNavigate()
    const handleOrderRoute = () => {
        setOrderProduct(product)
        setIsHomPage(true)
        route('/checkout')

    }

    return (
        <div>
            <button onClick={handleOrderRoute} type='button' className={styles.orderBtn}>অর্ডার করুন</button>
            {/* <OrderModal inputValue={inputValue} setInputValue={setInputValue} quantity={quantity} setQuantity={setQuantity} product={product} orderIcon={<button type='button' className={styles.orderBtn}>অর্ডার করুন</button>} isHomePage /> */}
        </div>
    );
}

export default OrderModalView;