/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import { fetchUrl } from '../../config';
import ProductView from '../../ProductView/ProductView';



function RelatedProduct(props) {
    const { categoryID, productId } = props
    const [relatedCategory, setRelatedCategory] = useState({})
    useEffect(() => {
        fetch( categoryID && `${fetchUrl}/api/category/${categoryID}/`)
            .then(res => res.json())
            .then(data => setRelatedCategory(data))
    }, [categoryID])

    const filterPd = relatedCategory.products && relatedCategory.products.filter(data => data.id !== productId)
    function shuffleArray(array) {
        for (let i = array && array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const randomProducts = shuffleArray(filterPd)
    return (
        <div>
            <ProductView products={randomProducts && randomProducts.slice(0, 10)} />
        </div>
    );
};

export default RelatedProduct;