import React, {useContext } from 'react';
import ProductView from '../ProductView/ProductView';
import { CartContext } from '../CartContext';
import LoadingRoyel from '../LoadingRoyel/LoadingRoyel';



function NewArrivel() {
    const {products, isLoadingForProdcutsFetch} = useContext(CartContext)
    const newProducts = [...products].sort((a, b) => parseFloat(b.id) - parseFloat(a.id))
    const popularProduct = [...products].sort((a, b) => Number(b.sold) - Number(a.sold))

    return (
        <div>
            <div>
            {isLoadingForProdcutsFetch ? <LoadingRoyel /> : null}
                <h4 className='text-center mb-3 mt-5'>New Arrival</h4>
                <ProductView products={newProducts.slice(0, 15)} />
            </div>
            <div className='mt-5'>
                <h4 className='text-center mb-3'>Popular Products</h4>
                <ProductView products={popularProduct.slice(0, 10)} />
            </div>
        </div>
    );
}

export default NewArrivel;