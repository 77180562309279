import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { fetchUrl } from '../../config';
import { Star } from '../../ProductView/ProductView';
import LoadingDark from '../../LodingDark/LoadingDark';


function ReviewsPage() {
    const token = Cookies.get('login_token');
    const [allReviews, setallReviews] = useState([])
    const [isLoading, setIsLoading] = useState([])

    useEffect(() => {

        if (token) {
            fetch(`${fetchUrl}/rating/user/`, { headers: token ? { "Authorization": `Bearer ${token}` } : {} })
                .then(res => res.json())
                .then(data => setallReviews(data))
                .then(setIsLoading(false))
        }
        else {

            setIsLoading(true)
        }

    }, [token])

    return (
        <div className='my5'>
              {isLoading ? <LoadingDark /> : null}
            <h3 className='text-center mt-5 pt-5'>My Reviews</h3>
            <div className='container'>
                {
                   allReviews && allReviews.map(data =>
                        <div className='my-5 p-3 border col-md-6 mx-auto' key={data.id}>
                            <Link className='text-decoration-none' href={`product/${data.product_slug}`} ><h5>{data.productx}</h5></Link>
                            <p>{data.rating_text}</p>
                            <Star starRating={data.rating_point} />
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default ReviewsPage;