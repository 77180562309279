import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { CartContext } from '../../CartContext';

function OrderConfrim() {
    const { orderData } = useContext(CartContext)
    useEffect(() => {
        if (orderData && window.dataLayer) {
            window.dataLayer.push({
                event: "purchase",
                ecommerce: {
                    currency: "BDT",
                    transaction_id: orderData.order_id,
                    value: orderData.price,
                    shipping: orderData.delivery_fee,
                    phone: orderData.phone,
                    cutomerName : orderData.name,
                    cutomerAddress : orderData.address,
                    country:'Bangladesh',
                    isOldCustomer:orderData.old_customer,

                    items: [
                        {
                            item_id: orderData.product_slug,
                            item_name: orderData.products,
                            quantity: orderData.quantity,
                            price: orderData.price,
                        }
                    ]
                }
            });
        }
    }, [orderData])
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 400);
    }, [])
    return <div>
        <Helmet>
            <title>Order Place Successful!! Safa Buy</title>
        </Helmet>
        <div className='text-center  mt-5 pt-5'>
            <div className='mt-5 '>
                <h1 className='text-success '> Order Place Successful !</h1>
                <p className='text-success '>আপনার অর্ডারটি সফলভাবে সম্পন্ন হয়েছে, <br />
                    আমাদের কল সেন্টার থেকে একজন কল দিয়ে <br />
                    আপনার অর্ডারটি কনফার্ম করবে।</p>
                <Link className='' to='/shop'> <button type='button' className=' btn btn-success'>আরো প্রোডাক্ট বাছাই করুন</button></Link>

            </div>
        </div>
    </div>
}

export default OrderConfrim;