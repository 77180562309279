import React from "react"
import { useParams } from "react-router-dom"
import CartPage from "./CartPage"


export default function CartPagezIndex() {
  const { cartItem } =  useParams()
  const x = cartItem.split('-')
const itemId = (x[x.length - 1])
  return (
    <div>
      <CartPage itemId={itemId} />
    </div>
  )
}