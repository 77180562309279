
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from "react-router-dom";
import Cookies from 'js-cookie';
import Home from './components/Home/Home'
import { CartProvider } from './components/CartContext';
import Nav from './components/Nav/Nav';
import NavMobile from './components/navMobile/NavMobile';
import Footer from './components/Footer/Footer';
import ProductPage from './components/Product/ProductPage';
import Categroypage from './components/Category/Categroypage';
import SearchPage from './components/search/SearchPage';
import CartPagezIndex from './components/cart/CartPageIndex';
import LoginPage from './components/login/LoginPage';
import RegistrationPage from './components/registration/RegistrationPage';
import AboutPage from './components/pages/about/AboutPage';
import ContactPage from './components/pages/contact/ContactPage';
import PrivacyPage from './components/pages/privacy-policy/PrivacyPage';
import ReturnPage from './components/pages/return-policy/ReturnPage';
import TermsPage from './components/pages/terms-conditions/TermsPage';
import ProfilePage from './components/profile/ProfilePage';
import AccountPage from './components/profile/account/AccountPage';
import ReviewsPage from './components/profile/reviews/ReviewsPage';
import Alerts from './components/Alert/Alert';
import Error from './components/Error/Error';
import ShopPage from './components/shop/ShopPage';
import OrderModal from './components/Order/OrderModal';
import OrderConfrim from './components/Order/OrderConfirm/OrderConfirm';











// create context



function App() {

  const isLogin = true;


  return (

    <CartProvider>
      <Router>
        <Alerts/>
        <Nav />
        <NavMobile />
        <Routes>
          <Route element={<ProtectedRoutes admin={isLogin} />}>
            <Route path='/profile' element={<ProfilePage />} />
            <Route path='/profile/account' element={<AccountPage />} />
            <Route path='/profile/reviews' element={<ReviewsPage />} />
          </Route>
        
          <Route path='/' element={<Home />} />
          <Route path='/product/:productSlug' element={<ProductPage />} />
          <Route path='/category/:categorySlug' element={<Categroypage />} />
          <Route path='/search/:searchParams' element={<SearchPage />} />
          <Route path='/cart/:cartItem' element={<CartPagezIndex />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/registration' element={<RegistrationPage />} />
          <Route path='/pages/about' element={<AboutPage />} />
          <Route path='/pages/contact' element={<ContactPage />} />
          <Route path='/pages/privacy-policy' element={<PrivacyPage />} />
          <Route path='/pages/return-policy' element={<ReturnPage />} />
          <Route path='/pages/terms-conditions' element={<TermsPage />} />
          <Route path='/shop' element={<ShopPage />} />
          <Route path='/checkout' element={<OrderModal />} />
          <Route path='/order-confirm' element={<OrderConfrim />} />
          <Route path='*' element={<Error />} />

        </Routes>
        <Footer />
      </Router>
    </CartProvider>


  );
};

export default App;

function ProtectedRoutes() {
  const useAuth = () => {
    const user = Cookies.get('login_token');
    if (user) {
      return true
    }
    return false

  }
  const admin = useAuth()
  return admin ? <Outlet /> : <Navigate to="/login" />
}


